import { Component, OnInit } from '@angular/core';
import { AlertType } from './alert.model';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass'],
})
export class AlertComponent implements OnInit {
  AlertType = AlertType;
  alertType: AlertType;
  message: string;
  showAlert = false;
  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alertService.showalertHappened.subscribe((alert) => {
      this.message = alert.message;
      this.alertType = alert.type;
      this.showAlert = true;
      setTimeout(() => {
        this.close();
      },5000)
    });
  }

  close(): void {
    this.showAlert = false;
    this.message = null;
    this.alertType = null;
  }
}
