<div class="navbar" [class.navbar--no-shadow]="isDark || !shadow" [class.navbar--admin]="darkAdmin">
  <app-close-icon
    class="menu__logo"
    (click)="toggleMenu()"
    [isOpen]="isOpen"
    [canClose]="canClose"
    [className]="(darkAdmin && !isOpen) ? '--admin' : ''"
  ></app-close-icon>
  <div class="navbar__title" [class.navbar--link]="link" (click)="navigateToLink()">
    <img loading="lazy" *ngIf="logo" class="navbar__logo {{className}}" [src]="logo" />{{
      title | translate: locale.language
    }}
  </div>
  <div *ngIf="isOpen" @slideFromRight class="menu-background">
    <ng-container *ngIf="isAuthenticated; else rasaLogo">
      <figure class="uncorked__header">
        <img loading="lazy" class="uncorked__cork" src="assets/icons/cork.svg"/>
        <figcaption class="uncorked__cork-title uncorked-title" translate>winery.uncorked_program</figcaption>
      </figure>
      <a (click)="toggleMenu()" class="uncorked__terms" routerLink="/how-it-works" routerLinkActive="--selected" translate>navbar.how-it-works</a>
    </ng-container>
    <ng-template #rasaLogo>
      <img loading="lazy" routerLink="/" class="navbar__rasa-logo" src="assets/icons/grupo-RA.svg" />
    </ng-template>
    <div class="menu__list">
      <img loading="lazy"
        class="menu__item"
        (click)="toggleMenu()"
        src="assets/logos/lariojaalta_logo.svg"
        routerLink="/la_rioja_alta"
      />
      <img loading="lazy"
        class="menu__item"
        (click)="toggleMenu()"
        src="assets/logos/torredeona_logo.svg"
        routerLink="/torre_de_ona"
      />
      <img loading="lazy"
        class="menu__item --lagardefornelos"
        (click)="toggleMenu()"
        src="assets/logos/lagardefornelos_logo.svg"
        routerLink="/lagar_d_fornelos"
      />
      <img loading="lazy"
        class="menu__item --aster"
        (click)="toggleMenu()"
        src="assets/logos/aster_logo.svg"
        routerLink="/aster"
      />
    </div>
    <div class="separator"></div>
    <div class="uncorked__program">
      <ng-container *ngIf="isAuthenticated; else notAuthenticated">
        <div class="uncorked__user" translate>
          <figure>
            <img loading="lazy" *ngIf="!user?.image" class="uncorked__user__avatar-img" src="/assets/icons/{{user.role | lowercase}}{{(isDark) ? '-w':''}}.svg" alt="user icon">
            <img loading="lazy" *ngIf="user?.image" class="uncorked__user__avatar-img" src="{{user.image}}" alt="user image" [lightbox]="0" (click)="userImageClick()">
          </figure>
          <span class="uncorked__user__text">
            {{user.name}} {{user.surname}}
          </span>
        </div>
        <nav class="uncorked__nav-list">
          <a class="uncorked__nav-item" routerLink="/dashboard" routerLinkActive="--selected">
            <figure class="uncorked__nav-figure">
              <img loading="lazy" class="uncorked__nav-img --invert" src="/assets/icons/perfil-header.svg" />
            </figure>
            <span class="uncorked__nav-text" translate>navbar.dashboard</span>
          </a>
          <a class="uncorked__nav-item" [routerLink]="['/profile']" routerLinkActive="--selected">
            <figure class="uncorked__nav-figure">
              <img loading="lazy" class="uncorked__nav-img" src="/assets/icons/{{user.role | lowercase}}-w.svg" />
            </figure>
            <span class="uncorked__nav-text" translate>navbar.profile</span>
          </a>
          <a class="uncorked__nav-item" routerLink="/transactions" routerLinkActive="--selected">
            <figure class="uncorked__nav-figure">
              <img loading="lazy" class="uncorked__nav-img" src="/assets/icons/corchos.svg" />
            </figure>
            <span class="uncorked__nav-text" translate>navbar.corks</span>
          </a>
          <a class="uncorked__nav-item" routerLink="/categories" routerLinkActive="--selected">
            <figure class="uncorked__nav-figure">
              <img loading="lazy" class="uncorked__nav-img" src="/assets/icons/regalos.svg" />
            </figure>
            <span class="uncorked__nav-text" translate>navbar.prizes</span>
          </a>
          <a *ngIf="showAdmin" class="uncorked__nav-item" routerLink="/management" routerLinkActive="--selected">
            <figure class="uncorked__nav-figure">
              <img loading="lazy" class="uncorked__nav-img" src="/assets/icons/admin-dashboard.svg" />
            </figure>
            <span class="uncorked__nav-text" translate>management.admin</span>
          </a>
          <a class="uncorked__nav-item" routerLink="/login" (click)="logout()">
            <figure class="uncorked__nav-figure">
              <img loading="lazy" class="uncorked__nav-img" src="/assets/icons/power-off.svg" />
            </figure>
            <span class="uncorked__nav-text" translate>public_navbar.logout</span>
          </a>
        </nav>
      </ng-container>
      <ng-template #notAuthenticated>
        <img loading="lazy" class="uncorked__logo" src="assets/icons/cork.svg"/>
        <div class="uncorked__title uncorked-title" translate>winery.uncorked_program</div>
        <a (click)="toggleMenu()" class="uncorked__terms" routerLink="/how-it-works" routerLinkActive="--selected" translate>navbar.how-it-works</a>
        <div
          class="button"
          routerLink="/login"
          (click)="toggleMenu()"
          translate
        >
          login.sign_in
        </div>
        <img loading="lazy" class="uncorked__img" src="/assets/backgrounds/home_bottles.png">
      </ng-template>
    </div>
  </div>
</div>
