import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map, Observable, Subject } from 'rxjs';
import { User } from '../core/auth/auth.model';
import { Utils } from '../shared/utils/utils';
import {
  Distributor,
  DistributorsResponse,
  UsersFilters,
  UsersResponse,
} from './users.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  userUpdate: Subject<any> = new Subject();
  constructor(private http: HttpClient) {}

  updateUser(id: string, user: User): Observable<any> {
    delete user.image;
    return this.http.patch(`api/users/${id}`, user).pipe(
      tap(() => {
        this.userUpdate.next(true);
      })
    );
  }

  updateUserImage(id: string, image: any): Observable<any> {
    return this.http
      .patch(`api/users/${id}/image`, this.toFormData({ image: image }))
      .pipe(
        tap(() => {
          this.userUpdate.next(true);
        })
      );
  }

  getUsers(filters: UsersFilters): Observable<UsersResponse> {
    return this.http.get<UsersResponse>('api/users', {
      params: Utils.getParams(filters),
    });
  }

  getDistributors(): Observable<Distributor[]> {
    return this.http
      .get<DistributorsResponse>(`api/distributors`)
      .pipe(map((res) => res.distributors));
  }

  getRankingPosition(): Observable<number> {
    return this.http
      .get<any>(`api/users/self/ranking-position`)
      .pipe(map((res) => res.position));
  }

  toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = (formValue as any)[key];
      formData.append(key, value);
    }

    return formData;
  }
}
