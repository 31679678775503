import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartService } from 'src/app/cart/cart.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.sass'],
})
export class PaginatorComponent implements OnInit {
  @Output() prevClick = new EventEmitter();
  @Output() nextClick = new EventEmitter();
  @Input() current: number;
  @Input() total: number;
  @Input() prevText = 'paginator.prev';
  @Input() nextText = 'paginator.next';

  constructor(public cartService: CartService) {}

  ngOnInit(): void {}

  onPrev(): void {
    if (this.current && this.current <= 1) {
      return;
    }
    this.prevClick.emit();
  }

  onNext(): void {
    if (this.current && this.current >= this.total) {
      return;
    }
    this.nextClick.emit();
  }
}
