import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AlertType } from '../shared/alert/alert.model';
import { AlertService } from '../shared/alert/alert.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private alertService: AlertService;

  constructor(private injector: Injector) {
    this.alertService = this.injector.get(AlertService);
  }

  handleError(error: Error | HttpErrorResponse) {
    console.error(error);

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 401:
          this.alertService.showAlert({
            message: 'Username or password are wrong',
            type: AlertType.error,
          });
          break;
        default:
          this.alertService.showAlert({
            message: (error.error)? error.error.message : error.message,
            type: AlertType.error,
          });
          break;
      }
    } else {
      this.alertService.showAlert({
        message: error.message,
        type: AlertType.error,
      });
    }
  }
}
