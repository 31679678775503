import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from  'ng-gallery/lightbox';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MY_FORMATS } from './app.constants';
import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './core/interceptor';
import { GlobalErrorHandler } from './errors/global-error.handler';
import { AppStorage, HttpTranslationLoader, l10nConfig } from './l10n-config';
import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    L10nTranslationModule.forRoot(l10nConfig, {
      storage: AppStorage,
      translationLoader: HttpTranslationLoader,
    }),
    L10nIntlModule,
    LoginModule,
    CoreModule,
    SharedModule,
    NgxSpinnerModule,
    MatNativeDateModule,
    GalleryModule,
    LightboxModule
  ],
  providers: [
    { provide: 'BASE_API_URL', useValue: environment.apiUrl },
    { provide: 'UNCORKED_API_URL', useValue: environment.uncorkedApiUrl },
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
