import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { Prize } from '../prizes/prize.model';
import { CartItem } from './cart.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartItemsSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject<
    CartItem[]
  >([]);
  cartItems: Observable<CartItem[]>;
  constructor(private http: HttpClient) {
    this.cartItems = this.cartItemsSubject.asObservable();
  }

  addPrize(prize: Prize, units: number = 1): void {
    let cartItems = this.cartItemsSubject.value;
    let index = cartItems.findIndex(
      (cartItem) => cartItem.prize._id == prize._id
    );
    if (index == -1) cartItems.push({ prize, units });
    else cartItems[index].units += units;

    this.cartItemsSubject.next([...cartItems]);
  }

  removePrize(prize: Prize, removeAll: boolean = false): void {
    let cartItems = this.cartItemsSubject.value;
    let cartItem = cartItems.find(
      (cartItem) => cartItem.prize._id == prize._id
    );
    if (cartItem) {
      cartItem.units = cartItem.units < 1 ? 0 : cartItem.units - 1;
      if (removeAll) {
        cartItems = cartItems.filter((c) => c !== cartItem);
      }
    }
    this.cartItemsSubject.next([...cartItems]);
  }

  clearCart(): void {
    this.cartItemsSubject.next([]);
  }

  redeem(): Observable<any> {
    let body = {
      prizes: this.cartItemsSubject.value.map((cartItem) => {
        return { prizeId: cartItem.prize._id, units: cartItem.units };
      }),
    };
    return this.http.post<any>(`api/prizes/redeem/`, body);
  }

  isBottomBarVisible(): Observable<boolean> {
    return this.cartItems.pipe(map((items) => items.length !== 0));
  }
}
