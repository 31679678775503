import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardService } from './core/guards/admin-guard.service';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { WebAuthGuardService } from './core/guards/web-auth-guard.service';
import { PlatformGuardService } from './core/guards/platform-guard.service';
import { LoginComponent } from './login/login.component';
import { WineryType} from './wineries/wineries.model';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'how-it-works',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./recover-password/recover-password.module').then(m => m.RecoverPasswordModule),
  },
  {
    path: 'set-new-password',
    loadChildren: () => import('./set-new-password/set-new-password.module').then(m => m.SetNewPasswordModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
    canActivate: [PlatformGuardService]
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule),
    canActivate: [PlatformGuardService]
  },
  {
    path: 'transactions',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
    canActivate: [PlatformGuardService, AuthGuardService]
  },
  {
    path: 'categories',
    loadChildren: () => import('./prizes/prizes.module').then(m => m.PrizesModule),
    canActivate: [PlatformGuardService, AuthGuardService]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [PlatformGuardService, AuthGuardService]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [PlatformGuardService, AuthGuardService]
  },
  {
    path: 'management',
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    canActivate: [AdminGuardService, PlatformGuardService]
  },
  {
    path: 'aster',
    loadChildren: () => import('./wineries/wineries.module').then(m => m.WineriesModule),
    data: { winery: WineryType.aster},
    canActivate: [PlatformGuardService]
  },
  {
    path: 'la_rioja_alta',
    loadChildren: () => import('./wineries/wineries.module').then(m => m.WineriesModule),
    data: { winery: WineryType.la_rioja_alta},
    canActivate: [PlatformGuardService]
  },
  {
    path: 'lagar_d_fornelos',
    loadChildren: () => import('./wineries/wineries.module').then(m => m.WineriesModule),
    data: { winery: WineryType.lagar_d_fornelos},
    canActivate: [PlatformGuardService]
  },
  {
    path: 'torre_de_ona',
    loadChildren: () => import('./wineries/wineries.module').then(m => m.WineriesModule),
    data: { winery: WineryType.torre_de_ona},
    canActivate: [PlatformGuardService]
  },
  {
    path: 'download-app',
    loadChildren: () => import('./app-redirect/app-redirect.module').then(m => m.AppRedirectModule),
    data: { winery: WineryType.aster}
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "top",
    anchorScrolling: "enabled"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
