<div class="layout" [class.login__desktop]="isDesktop">
  <app-public-navbar [logo]="'assets/icons/cork.svg'"
                     [title]="'Uncorked Program'"
                     [canClose]="true"
                     [shadow]="false"
                     (close)="onClose()"></app-public-navbar>
  <form class="login-form" [formGroup]="loginForm">
    <img loading="lazy" class="login__logo" src="assets/icons/cork.svg"/>
    <div class="login__title" translate>login.title</div>
    <div class="login__box">
      <input
        class="login__input"
        type="email"
        placeholder="login.email"
        formControlName="email"
        l10nTranslate
        l10n-placeholder
      />
      <div class="login__error">
        <span *ngIf="submitted && loginForm.controls['email'].invalid" translate>
          validations.email
        </span>
      </div>
    </div>
    <div class="login__box">
      <input
        class="login__input"
        type="password"
        placeholder="login.password"
        formControlName="password"
        l10nTranslate
        l10n-placeholder
      />
      <div class="login__error">
        <span *ngIf="submitted && loginForm.controls['password'].invalid" translate>
          validations.required_password
        </span>
      </div>
    </div>
    <button class="button" (click)="login()" translate>
      login.sign_in
    </button>
    <div class="login__link">
      <a routerLink="/recover-password" translate>login.forgot_password</a>
    </div>
  </form>
</div>
