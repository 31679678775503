import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { L10nTranslationService } from 'angular-l10n';
import { SafeArea } from "capacitor-plugin-safe-area";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  constructor(
    private translation: L10nTranslationService
  ) {
    this.translation.setLocale({
      language: 'en-US',
      currency: 'USD',
      timeZone: 'America/Los_Angeles',
      units: { length: 'mile' },
    });

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      var r: any = document.querySelector(':root');
      r.style.setProperty('--top-inset', Capacitor.getPlatform() == 'ios' ? insets.top : 0 + 'px');
    })
  }
}
