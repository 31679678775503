import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';
import {LoginResponse, roles} from "../core/auth/auth.model";
import {PlatformGuardService} from "../core/guards/platform-guard.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  isDesktop: boolean = false;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private platformAuthService: PlatformGuardService) {
    this.isDesktop = !this.platformAuthService.checkPlatform();
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
      remember: [false],
    });
  }

  ngOnInit(): void {}

  login() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.authService
        .login(
          this.loginForm.value.email,
          this.loginForm.value.password,
          this.loginForm.value.remember
        )
        .subscribe({
          next: (login: LoginResponse) => {
            const url = (login.user.role === roles.ADMIN) ? '/management' : '/dashboard';
            this.router.navigate([url]);
          },
          error: () => {
            throw new HttpErrorResponse({ status: 401 });
          },
        });
    }
  }


  onClose(){
    this.router.navigate(['/']).then()
  }
}
