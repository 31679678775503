<div class="paginator" [class.cart-visible]="cartService.isBottomBarVisible() | async">
  <div class="paginator-item" [class.paginator-item--hidden]="current <= 1" (click)="onPrev()">
    <img loading="lazy" class="paginator-item__button" src="assets/icons/arrow-l.svg" />
    <div class="paginator-item__text" translate>{{prevText}}</div>
  </div>
  <div *ngIf="current && total" class="paginator-page">
    {{current}} of {{total | ceil}}
  </div>
  <div class="paginator-item" [class.paginator-item--hidden]="current >=total" (click)="onNext()">
    <div class="paginator-item__text paginator-item__text--next" translate>
      {{nextText}}
    </div>
    <img loading="lazy" class="paginator-item__button" src="assets/icons/arrow-r.svg" />
  </div>
</div>
