import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';

@Pipe({
  name: 'icon',
})
export class IconPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(icon: string): string {
    const route = 'assets/icons/';
    const adminRoute = 'assets/icons/dark/';

    return (this.authService.isDark() ? adminRoute : route) + icon;
  }
}
