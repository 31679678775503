import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'ceil',
})
export class CeilPipe implements PipeTransform {
  transform(num: number): number {
    return Math.ceil(num);
  }
}
