import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-close-icon',
  templateUrl: './close-icon.component.html',
  styleUrls: ['./close-icon.component.sass'],
})
export class CloseIconComponent implements OnInit {
  @Input() canClose = false;
  @Input() isOpen = false;
  @Input() className = '';
  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      icon: [this.canClose || this.isOpen],
      disabled: this.canClose,
    });
  }
}
