import { Component, OnInit } from '@angular/core';

const MODAL_CLASS = 'modal-open';

@Component({
  template: '',
})
export class ModalComponent implements OnInit {

  ngOnInit(): void {
    if (!document.body.classList.contains(MODAL_CLASS)) {
      document.body.classList.toggle(MODAL_CLASS);
    }
  }

  ngOnDestroy(): void {
    if (document.body.classList.contains(MODAL_CLASS)) {
      document.body.classList.remove(MODAL_CLASS);
    }
  }
}
