import {Distributor} from "../../users/users.model";

export interface LoginDTO {
  email: string,
  password: string
}

export interface LoginResponse {
  accessToken: string,
  refreshToken: string,
  user: User
}

export interface User {
  _id: string,
  name: string,
  surname: string,
  address: string,
  city: string,
  zipcode: string,
  email: string,
  emailVerified: boolean,
  role: roles,
  score: number,
  company?: string,
  manager?: string,
  excelName: string,
  image?: string
  earnedScore? :number;
  spentScore?: number;
  distributor?: Distributor
}

export interface UserRanking {
  position?: number;
  name?: string;
  role?: string;
  score?: number;
  isUser?: boolean;
  image?: string;
  me?: boolean;
}

export interface CreateUser {
  name:	string,
  surname:	string,
  email:	string,
  password:	string,
  role:	string,
  manager?: string
}

export enum roles {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SELLER = 'SELLER'
}
