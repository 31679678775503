import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NavItem } from './navbar.model';
import { roles } from '../../core/auth/auth.model';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnInit {
  @Input() showNav = true;
  @Input() current: NavItem;
  NavItem = NavItem;
  rolesList = roles;
  role: string;
  score: number;
  showAdmin: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.role = this.authService.getRole();
    this.authService.updateScore();
    this.authService.score$.subscribe((score) => (this.score = score));
    this.showAdmin =
      Capacitor.getPlatform() === 'web' && this.role === roles.ADMIN;
  }
}
