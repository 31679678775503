import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { PublicNavbarComponent } from './navbar/public-navbar/public-navbar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { CeilPipe } from './paginator/ceil.pipe';
import { IconPipe } from './icons/icon.pipe';
import { CloseIconComponent } from './navbar/close-icon/close-icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { MatDividerModule } from '@angular/material/divider';
import { LightboxModule } from 'ng-gallery/lightbox';

@NgModule({
  declarations: [
    NavbarComponent,
    PublicNavbarComponent,
    PaginatorComponent,
    CeilPipe,
    IconPipe,
    CloseIconComponent,
    AlertComponent,
    ModalComponent,
    FileUploadComponent,
    RangeSliderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    L10nTranslationModule,
    L10nIntlModule,
    ReactiveFormsModule,
    MatDividerModule,
    FormsModule,
    LightboxModule
  ],
  exports: [
    NavbarComponent,
    PublicNavbarComponent,
    PaginatorComponent,
    IconPipe,
    AlertComponent,
    FileUploadComponent,
    RangeSliderComponent,
  ],
})
export class SharedModule {}
