<form class="profile-form" [formGroup]="form">
  <div class="nav-container">
    <input class="checkbox" type="checkbox" formControlName="icon"/>
    <div class="hamburger-lines {{className}}">
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
    </div>
  </div>
</form>
