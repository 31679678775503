export interface Alert {
  message: string;
  type: AlertType;
}

export enum AlertType {
  success = 'success',
  error = 'error',
  alert = 'alert',
}
