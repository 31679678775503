import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { TOKEN_KEY } from './auth.constants';
import { CreateUser, LoginDTO, LoginResponse, roles, User } from './auth.model';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

const jwtHelper = new JwtHelperService();
const DARK_CLASS = 'dark';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenSubject: BehaviorSubject<string>;
  public token: Observable<string>;

  private scoreSubject: BehaviorSubject<number>;
  public score$: Observable<number>;

  constructor(private http: HttpClient, private router: Router) {
    this.tokenSubject = new BehaviorSubject<string>(
      localStorage.getItem('token') || ''
    );
    this.token = this.tokenSubject.asObservable();
    this.scoreSubject = new BehaviorSubject<number>(0);
    this.score$ = this.scoreSubject.asObservable();
    this.setDarkMode();
  }

  get currentToken(): string {
    return this.tokenSubject.value;
  }

  setToken(token: string): void {
    this.tokenSubject.next(token);
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
    this.setDarkMode(token);
  }

  isAuthenticated(): boolean {
    const token = this.currentToken;
    const isAuth = !jwtHelper.isTokenExpired(token);
    if (!isAuth) this.setToken('');
    return isAuth;
  }

  login(
    email: string,
    password: string,
    remember: boolean
  ): Observable<LoginResponse> {
    const body: LoginDTO = {
      email: email,
      password: password,
    };
    return this.http.post<LoginResponse>('api/auth/login', body).pipe(
      tap((res) => {
        if (res) {
          this.setToken(res.accessToken);
        }
      })
    );
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post('api/auth/request-reset-password', { email: email });
  }

  setNewPassword(password: string): Observable<any> {
    return this.http.post('api/auth/set-new-password', { password: password });
  }

  register(body: CreateUser): Observable<any> {
    body.manager = body.role == roles.ADMIN ? undefined : body.manager;
    return this.http.post('api/auth/register', body);
  }

  confirmEmail(token: string): Observable<any> {
    this.setToken(token);
    return this.http.post('api/auth/confirm-email', {});
  }

  getUserInfo(): Observable<User> {
    return this.http.get<User>('api/auth/self').pipe(
      tap((user) => {
        this.scoreSubject.next(user?.score);
      })
    );
  }

  getRole(token = this.currentToken): roles {
    const decodedToken = this.getDecodedToken(token);
    return decodedToken ? decodedToken.role : null;
  }

  getUser(token = this.currentToken): any {
    return this.getDecodedToken(token);
  }

  isAdmin(): boolean {
    return this.isAuthenticated() && this.getRole() == roles.ADMIN;
  }

  isDark(token = this.currentToken): boolean {
    return this.isAuthenticated() && this.getRole(token) == roles.MANAGER;
  }

  logout(): void {
    this.setToken('');
    this.router.navigate(['login']);
  }

  setDarkMode(token = this.currentToken): void {
    if (token) {
      this.isDark(token) ? AuthService.turnOnDarkMode() : AuthService.turnOffDarkMode();
    } else {
      AuthService.turnOffDarkMode();
    }
  }

  updateScore(): void {
    this.getUserInfo().subscribe();
  }

  private static turnOffDarkMode(): void {
    if (document.body.classList.contains(DARK_CLASS)) {
      document.body.classList.remove(DARK_CLASS);
    }
    if (Capacitor.getPlatform() === 'ios') {
      StatusBar.setStyle({ style: Style.Light }).then();
    }
  }

  private static turnOnDarkMode(): void {
    if (!document.body.classList.contains(DARK_CLASS)) {
      document.body.classList.toggle(DARK_CLASS);
    }
    if (Capacitor.getPlatform() === 'ios') {
      StatusBar.setStyle({ style: Style.Dark }).then();
    }
  }

  private getDecodedToken(token: string): any {
    return jwtHelper.decodeToken(token);
  }
}
