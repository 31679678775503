<div class="slider-container">
  <div class="slider-bar" [id]="sliderId"></div>
  <div class="slider-info">
    <div class="floor" l10nNumber>{{ this.floor }}</div>
    <div class="min-max">
      <span l10nNumber>{{ this.min }}</span> ><
      <span l10nNumber>{{ this.max }}</span>
    </div>
    <div class="ceil" l10nNumber>{{ this.ceil }}</div>
  </div>
</div>
