import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UsersService } from 'src/app/users/users.service';
import { slideFromRight } from '../../../animations/animations';
import { roles, User } from '../../../core/auth/auth.model';

@Component({
  selector: 'app-public-navbar',
  templateUrl: './public-navbar.component.html',
  styleUrls: ['./public-navbar.component.sass'],
  animations: [slideFromRight],
})
export class PublicNavbarComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() canClose = false;
  @Input() darkAdmin = false;
  @Input() shadow = true;
  @Input() logo: string;
  @Input() className: string;
  @Input() link: string;
  @Output() close = new EventEmitter();
  user: User;
  isAuthenticated: boolean;
  isOpen: boolean = false;
  isDark: boolean;
  showAdmin: boolean;
  subs = new Subscription();
  galleryItems: GalleryItem[];

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private router: Router,
    private authService: AuthService,
    private usersService: UsersService,
    private gallery: Gallery
  ) {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.isDark = this.authService.isDark();
  }

  ngOnInit(): void {
    if (this.isAuthenticated) {
      this.updateUser();
      this.subs = this.usersService.userUpdate.subscribe(() => {
        this.updateUser();
      });
    }
  }

  updateUser(): void {
    this.subs = this.authService.getUserInfo().subscribe({
      next: (user: User) => {
        this.user = user;
        this.showAdmin =
          Capacitor.getPlatform() === 'web' && user?.role === roles.ADMIN;
      },
      error: (err) => (this.user = null),
    });
  }

  userImageClick(): void {
    this.galleryItems = [new ImageItem({ src: this.user.image })];
    this.gallery.ref().load(this.galleryItems);
  }

  toggleMenu(): void {
    if (this.canClose) {
      this.close.emit();
      return;
    }
    this.isOpen = !this.isOpen;
  }

  logout(): void {
    this.authService.logout();
    this.toggleMenu();
  }

  navigateToLink() {
    if (this.link) {
      this.router.navigate([this.link]).then(() => {
        this.isOpen = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
