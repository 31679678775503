import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor {
  constructor(
    @Inject('BASE_API_URL') private baseUrl: string,
    @Inject('UNCORKED_API_URL') private uncorkedUrl: string
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.startsWith('./assets') &&
      !req.url.startsWith(this.baseUrl)
    ) {
      req = req.clone({ url: `${this.uncorkedUrl}${req.url}` });
    }
    return next.handle(req);
  }
}
