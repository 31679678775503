import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from './alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public showalertHappened: Subject<Alert>;
  public hidealertHappened: Subject<Alert>;

  constructor() {
    this.showalertHappened = new Subject<Alert>();
    this.hidealertHappened = new Subject();
  }

  public showAlert(alert: Alert): void {
    this.showalertHappened.next(alert);
  }
  public hideAlert(): void {
    this.hidealertHappened.next(null);
  }
}
