import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpUrlInterceptor } from "./http-url-interceptor";
import { JwtInterceptor } from "./jwt-interceptor";
import { LoadingInterceptor } from "./loading-interceptor";

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];
