export class Utils {
  public static getParams(obj: any): any {
    Object.keys(obj).forEach((key) =>
      obj[key] === undefined || obj[key] === null || obj[key] === ''
        ? delete obj[key]
        : {}
    );
    return obj;
  }
}
