<div class="nav" [class.nav--hide-icons]="!showNav">
  <div *ngIf="showNav" class="nav-list">
    <a class="nav-item" routerLink="/dashboard">
      <div
        class="nav-item__icon"
        [class.nav-item__icon--selected]="current == NavItem.dashboard"
      >
        <img loading="lazy" class="nav-item__img" src="/assets/icons/perfil.svg" />
      </div>
      <div class="nav-item__name" translate>navbar.dashboard</div>
    </a>
    <div class="separator"></div>
    <a class="nav-item" routerLink="/profile">
      <div
        class="nav-item__icon"
        [class.nav-item__icon--selected]="current == NavItem.profile"
      >
        <img loading="lazy"
          class="nav-item__img nav-item__img"
          src="/assets/icons/{{ role | lowercase }}-w.svg"
        />
      </div>
      <div class="nav-item__name" translate>navbar.profile</div>
    </a>
    <div class="separator"></div>
    <a class="nav-item" routerLink="/transactions">
      <div
        class="nav-item__icon"
        [class.nav-item__icon--selected]="current == NavItem.corks"
      >
        <img loading="lazy" class="nav-item__img" src="/assets/icons/corchos.svg" />
      </div>
      <div class="nav-item__name" translate>navbar.corks</div>
    </a>
    <div class="separator"></div>
    <a class="nav-item" routerLink="/categories">
      <div
        class="nav-item__icon"
        [class.nav-item__icon--selected]="current == NavItem.prizes"
      >
        <img loading="lazy" class="nav-item__img" src="/assets/icons/regalos.svg" />
      </div>
      <div class="nav-item__name" translate>navbar.prizes</div>
    </a>
    <ng-container *ngIf="showAdmin">
      <div class="separator"></div>
      <a class="nav-item" routerLink="/management" >
        <div
          class="nav-item__icon"
          [class.nav-item__icon--selected]="current == NavItem.prizes"
        >
          <img loading="lazy" class="nav-item__img" src="/assets/icons/admin-dashboard-w.svg" />
        </div>
        <div class="nav-item__name" translate>navbar.admin</div>
      </a>
    </ng-container>
  </div>
  <div class="corks">
    <div class="corks__units" l10nNumber>{{ score || 0 }}</div>
    <div class="corks__title" translate>navbar.score</div>
  </div>
</div>
