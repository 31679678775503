export enum WineryType {
  aster = 'Áster',
  la_rioja_alta = 'La Rioja Alta, S.A.',
  lagar_d_fornelos = 'Lagar de Fornelos',
  torre_de_ona = 'Torre de Oña'
}

export interface Winery {
  enoturismo: string;
  descripcion: string;
}

export interface Bottle {
  anio: number;
  bodega: string;
  botella: string;
  cata: string;
  color: string;
  cosecha: string;
  elaboracioncrianza: string;
  envejecimiento: string;
  envejecimientoeu: string;
  etiqueta: string;
  ficha_cata: TastingSheet[];
  imagenficha: string;
  link_tienda: string;
  maridaje: string;
  nombre: string;
  orden: number;
  origen: string;
  origen_definicion: string;
  premios: any[];
  sello: Stamp[];
  slug: string;
  textodestacado: string;
  url: string;
  uvas: string[];
  video_cata: string;
  vinedos: string;
}

export interface TastingSheet {
  ficha: string;
  fichaen: string;
  nombre: string;
}

export interface Stamp {
  nombre: string;
  puntuacion: number;
  imagen: string;
}
