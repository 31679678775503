import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import noUiSlider from 'nouislider';

@Component({
  selector: 'ngx-mat-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.sass'],
})
export class RangeSliderComponent implements OnInit, AfterViewInit {
  @Input() min: number;
  @Input() max: number;
  @Input() sliderId = 'slider';
  @Output() output = new EventEmitter<any>();
  floor: number;
  ceil: number;

  ngOnInit(): void {
    this.floor = this.min;
    this.ceil = this.max;
  }

  ngAfterViewInit(): void {
    var range = document.getElementById(this.sliderId);
    noUiSlider.create(range, {
      start: [this.min, this.max],
      connect: true,
      step: 1,
      range: {
        min: this.min,
        max: this.max,
      },
    });

    (range as any).noUiSlider.on('change', () => {
      let [min, max] = (range as any).noUiSlider.getPositions();
      this.min = Math.round((this.ceil * min) / 100);
      this.max = Math.round((this.ceil * max) / 100);
      this.output.emit({
        min: this.min,
        max: this.max,
      });
    });
  }
}
